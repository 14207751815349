import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import SectionWrapper from '../../../components/SectionWrapper';
import styles from './styles.module.sass';
import SubLinks from '../SubLinks';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        pic: imageSharp(fluid: {originalName: {eq: "Use-Case-Digital-Documentation.jpg"}}) {
          fluid(maxWidth: 1922, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ pic }) => (
      <SectionWrapper id={i18n('_hash:digital-documentation')}>
        <Grid container justify='center' alignContent='center' alignItems='center'
          className={styles.container}>
          <Grid item xs={12} sm={12} md={12} lg={6}
            className={styles.imageWrapper}>
            <Img imgStyle={{ objectFit: 'contain', borderRadius: '16px' }}
              objectFit='contain'
              className={styles.imageContainer}
              fluid={pic.fluid}
              alt={i18n('Store and view all vehicle-related documents')}/>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={6}
            alignContent='center'
            alignItems='center'
            justify='flex-start'
            className={styles.callToActionContainer}>
            <Typography variant='h3'
              weight='bold'
              align='left'
              className={styles.titleContainer}>
              {i18n('Digital documentation')}
            </Typography>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('Store and view any vehicle-related documents in one place. An organised order of events and information such as what, when and who performed service is an important part of the ownership.')}
            </Typography>
            <br/>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('Other documents like leasing or insurance agreements come handy when an unexpected situation occurs. Having information in a digital format helps to keep track and make informed decisions as well as sharing internally or with potential buyers.')}
            </Typography>
            <SubLinks sublinks={[{
              text: i18n('Fleet management'),
              url: i18n('_url:fleet-management')
            },{
              text: i18n('Repair shops'),
              url: i18n('_url:repair-shop')
            }, {
              text: i18n('Leasing'),
              url: i18n('_url:leasing')
            }, {
              text: i18n('Dealerships'),
              url: i18n('_url:dealership')
            }, {
              text: i18n('Get started'),
              url: i18n('_url:sign-up'),
              button: true,
            }]}/>
          </Grid>
        </Grid>
      </SectionWrapper>
    )}/>
);