import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import SectionWrapper from '../../../components/SectionWrapper';
import styles from './styles.module.sass';
import SubLinks from '../SubLinks';

export default () => {
  const largeScreen = useMediaQuery('@media(min-width: 1280px)'); //This one should be done properly and use useMediaQuery(theme => theme.breakpoints.up('md'));
  return <StaticQuery
    query={graphql`
      query {
        pic: imageSharp(fluid: {originalName: {eq: "Use-Case-Green-Driving.jpg"}}) {
          fluid(maxWidth: 1922, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ pic }) => (
      <SectionWrapper  id={i18n('_hash:green-driving')}>
        <Grid container justify='center' alignContent='center' alignItems='center'
          className={styles.container}>
            {largeScreen 
              ? null 
              : <Grid item xs={12} sm={12} md={12} lg={6}
                className={styles.imageWrapper}>
                <Img imgStyle={{ objectFit: 'contain', borderRadius: '16px' }}
                  objectFit='contain'
                  className={styles.imageContainer}
                  fluid={pic.fluid}
                  alt={i18n('Reduce CO2 emissions for your fleet')}/>
              </Grid>
            }
          <Grid item container xs={12} sm={12} md={12} lg={6}
            alignContent='center'
            alignItems='center'
            justify='flex-start'
            className={styles.callToActionContainer}>
            <Typography variant='h3'
              weight='bold'
              align='left'
              className={styles.titleContainer}>
              {i18n('Green Driving')}
            </Typography>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('Vehicle owners should be aware of ways that can help them to improve their driving. Through the use of data, the opportunity for greener driving is more accessible to everyone.')}
            </Typography>
            <br/>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('From a driver perspective, there could be ways to reduce fuel consumption or improve driving habits by becoming aware of events as a part of the driving. From a fleet manager perspective, one can improve the overall fleet performance by following average fuel consumption, idling events and vehicle utilization to reduce CO2 emissions.')}
            </Typography>
            <SubLinks sublinks={[{
              text: i18n('Fleet management'),
              url: i18n('_url:fleet-management')
            }, {
              text: i18n('Drivers'),
              url: i18n('_url:drivers')
            }, {
              text: i18n('Get started'),
              url: i18n('_url:sign-up'),
              button: true,
            }]}/>
          </Grid>
          {largeScreen 
            ? <Grid item xs={12} sm={12} md={12} lg={6}
              className={styles.imageWrapper}>
              <Img imgStyle={{ objectFit: 'contain', borderRadius: '16px' }}
                objectFit='contain'
                className={styles.imageContainer}
                fluid={pic.fluid}
                alt={i18n('Reduce CO2 emissions for your fleet')}/>
            </Grid>
            : null
          }
        </Grid>
      </SectionWrapper>
    )}/>
};