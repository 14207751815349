import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import SectionWrapper from '../../../components/SectionWrapper';
import styles from './styles.module.sass';
import SubLinks from '../SubLinks';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        pic: imageSharp(fluid: {originalName: {eq: "Use-Case-Customer-Satisfaction.jpg"}}) {
          fluid(maxWidth: 1922, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ pic }) => (
      <SectionWrapper id={i18n('_hash:customer-satisfaction')}>
        <Grid container justify='center' alignContent='center' alignItems='center'
          className={styles.container}>
          <Grid item xs={12} sm={12} md={12} lg={6}
            className={styles.imageWrapper}>
            <Img imgStyle={{ objectFit: 'contain', borderRadius: '16px' }}
              objectFit='contain'
              className={styles.imageContainer}
              fluid={pic.fluid}
              alt={i18n('Minimal efforts on car-related tasks')}/>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={6}
            alignContent='center'
            alignItems='center'
            justify='flex-start'
            className={styles.callToActionContainer}>
            <Typography variant='h3'
              weight='bold'
              align='left'
              className={styles.titleContainer}>
              {i18n('Customer satisfaction')}
            </Typography>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('The primary reasoning for having a car is to get from A to B. It cannot be expected that car owners are experts in how a vehicle functions or what it needs are.')}
            </Typography>
            <br/>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('For optimal satisfaction, owners want a safe and reliable vehicle, minimal efforts on car-related tasks, no worries in case of issues, and all that enforced by online-dominated experience. The service provider should be closer to this scenario in order to keep customers satisfied.')}
            </Typography>
            <SubLinks sublinks={[{
              text: i18n('Repair shops'),
              url: i18n('_url:repair-shop')
            }, {
              text: i18n('Leasing'),
              url: i18n('_url:leasing')
            }, {
              text: i18n('Dealerships'),
              url: i18n('_url:dealership')
            }, {
              text: i18n('Get started'),
              url: i18n('_url:sign-up'),
              button: true,
            }]}/>
          </Grid>
        </Grid>
      </SectionWrapper>
    )}/>
);